<template>
  <el-form class="form" ref="formData" :model="formData" :rules="rules" size="medium" label-width="120px" :disabled="isView">
    <el-row>
      <el-col :span="24">
        <el-form-item label="客户企业名称" prop="EnterpriseName">
          <el-input v-if="isView || isEdit" v-model="formData.EnterpriseName" readonly disabled />
          <el-autocomplete v-else v-model="formData.EnterpriseName" :trigger-on-focus="false" :fetch-suggestions="searchEnterprise"
            placeholder="请输入企业全称" clearable style="width:100%" @select="setEnterpriseInfo" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <el-form-item label="客户分类" prop="CooperationTagList">
          <el-select v-model="formData.CooperationTagList" value-key="TagName" multiple filterable allow-create default-first-option
            placeholder="请选择分类" :style="{ width: '100%' }">
            <el-option v-for="item in UnitTagOptions" :key="item.TagName" :label="item.TagName" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="12">
        <el-form-item label="客户联系人" prop="EnterpriseContacts">
          <el-input v-model="formData.EnterpriseContacts" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="客户联系方式" prop="EnterprisePhone">
          <el-input v-model="formData.EnterprisePhone" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="是否对外展示">
          <el-radio-group v-model="isPublic">
            <el-radio :label="false">否</el-radio>
            <el-radio :label="true">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
    <template v-if="isPublic">
      <div class="info-span">对外展示此客户信息需要完善下面内容</div>
      <el-form ref="formCompany" :model="companyForm" :rules="rulesCompany" size="medium" label-width="120px" :disabled="isView">
        <el-row>
          <el-col :span="24">
            <el-form-item label="企业logo" prop="Logo">
              <picture-selector v-model="companyForm.Logo" name="Logo" ref="Logo" remarks="200*200" uploadPath="/DataCenterFile/UploadSingleFile"
                :width="100" :height="100" @input="updateForm">
              </picture-selector>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属行业" prop="Industry">
              <el-cascader v-model="companyForm.Industry" :show-all-levels="false" :props="industryProps" :options="industryOptions"
                style="width:100%" placeholder="请选择所属行业">
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业性质" prop="Nature">
              <el-select v-model="companyForm.Nature" placeholder="请选择" class="width100">
                <el-option v-for="item in natureOptions" :key="item.Value" :label="item.Name" :value="item.Value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="企业规模" prop="Scale">
              <el-select v-model="companyForm.Scale" placeholder="请选择" class="width100">
                <el-option v-for="item in scaleOptions" :key="item.Id" :label="item.Name" :value="item.Value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所在区域" prop="Area">
              <el-cascader v-model="companyForm.Area" :props="propsArea" :options="areaTree" class="width100" placeholder="请选择所属地区"
                @change="areaChange">
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="详细地址" prop="Address">
              <el-input v-model="companyForm.Address" placeholder="请填写详细地址" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="宣传视频">
              <upload-material @input="uploadedResourse" :limit-count="1" :show-file="true" :value="companyForm.PromotionalVideo"
                limit-type="video/mp4" accept=".mp4" remark-text="只能上传mp4格式的视频文件"></upload-material>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="企业简介">
              <rich-text v-model="companyForm.Introduction" ref="Introduction" name="Introduction" uploadPath="/DataCenterFile/UploadSingleFile"
                @input="updateForm"></rich-text>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <el-row v-if="!isView">
      <el-col :span="24">
        <el-form-item size="large">
          <el-button type="primary" v-if="!isView" @click="submitForm" :loading="submitting">确认</el-button>
          <el-button @click="cancle">返回</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { mapGetters } from 'vuex';
import { getInfo, insert, edit } from "@/api/cooperation";
import {createEntity} from "@/api/enterprise/enterpriseInfo";
import { searchEnterpriseBaseList ,getEnterpriseBaseByName} from '@/api/enterprise/enterpriseInfo';
import { CooperationTypeEnum, StaffTypeEnum } from '@/utils/constant';
import { isPhone } from '@/utils/validate';
import PictureSelector from "@/components/base/PictureSelector";
import RichText from "@/components/base/RichText";
import UploadMaterial from "@/components/base/UploadChunk";
import { getAttributeOptions, updateEntity } from "@/api/enterprise/enterpriseInfo";
import { getUnitTagList } from '@/api/unittag'
export default {
  components: { PictureSelector, RichText, UploadMaterial },
  props: {
    id: {
      type: String,
      default: ''
    },
    isView: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {
        Id: this.$route.query.id || undefined,
        Type: CooperationTypeEnum.EnterpriseToEnterprise,
        UnitId: undefined,
        EnterpriseName: undefined,
        EnterpriseContacts: undefined,
        EnterprisePhone: undefined,

        CooperationTagList: []
      },
      UnitTagOptions: [],
      rules: {
        EnterpriseName: [
          {
            required: true,
            message: '请输入企业名称',
            trigger: 'blur',
          }
        ],
        EnterpriseContacts: [
          {
            required: true,
            message: '请输入企业联系人',
            trigger: 'blur',
          }
        ],
        EnterprisePhone: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: 'blur',
            // validator: (_rule, value, callback) => {
            //   if (!isPhone(value)) {
            //     callback(new Error("请输入正确的手机号"));
            //   } else {
            //     callback();
            //   }
            // },
          }
        ],
      },
      rulesCompany: {
        Code: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Logo: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Nature: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Industry: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Area: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Address: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Scale: [{ required: true, message: "此项为必填项", trigger: "blur" }],
      },
      submitting: false,
      loaded: false,
      industryOptions: [],
      formData: {},
      natureOptions: [],
      scaleOptions: [],
      areaTree: [],
      propsArea: {
        value: "Code",
        label: "Name",
        children: "Children"
      },
      industryProps: {
        value: "Id",
        label: "Name",
        children: "Children"
      },
      isPublic: false,
      companyForm: {}
    }
  },
  computed: {
    ...mapGetters(['employee'])
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      getAttributeOptions().then(res => {
        if (res.IsSuccess) {
          this.industryOptions = res.Result.industryOptions;
          this.natureOptions = res.Result.natureOptions;
          this.scaleOptions = res.Result.scaleOptions;
          this.areaTree = this.deleteChildren(res.Result.areaTree.Children);
          this.isLoaded = true;
        }
      });
      if ((this.isView || this.isEdit) && this.formData.Id) {
        const { Result } = await getInfo(this.formData.Id);
        this.formData = Object.assign({}, this.formData, Result);
      } else {
        const { Employer: { Id } = {} } = this.employee.find(({ StaffType }) => StaffType === StaffTypeEnum.Enterprise) || {};
        this.formData.UnitId = Id;
      }

      getUnitTagList(this.formData.UnitId).then(({ Result }) => {
        const { CooperationTagList = [] } = this.formData;
        this.UnitTagOptions = Result.map(item => {
          const selectedItem = CooperationTagList.find(({ TagName }) => TagName === item.Text);
          if (selectedItem === undefined) {
            return {
              TagName: item.Text,
              TagId: item.Value
            };
          } else {
            return {
              TagName: item.Text,
              TagId: item.Value,
              Id: selectedItem.Id
            };
          }
        });
        this.loaded = true;
      }).catch(_ => {
        this.loaded = true;
      });
    },
    async searchEnterprise(query, cb) {
      if (query) {
        const { Result } = await searchEnterpriseBaseList(query);
        cb(Result.map(({ Text }) => ({
          value: Text,
        })));
      } else {
        cb([])
      }
    },
    async  submitForm() {
      if (!this.formData.UnitId) {
        this.$message.error("新增失败，请稍后重试");
        return;
      }
      this.$refs['formData'].validate(msg => {
        if (!msg) {
          return;
        } 
        if(this.isPublic){
          this.$refs['formCompany'].validate(async valid=>{
            if(valid){
              var postCompany=Object.assign({},this.companyForm)
              postCompany.Industry = this.companyForm.Industry[1]
              //没有Id，要先插入到库中,有Id，不能再改？（加入审核机制？不然改不改都有问题）
              if(!this.companyForm.Id){ 
                this.submitting = true;
                var resBase=await createEntity(postCompany)
                this.submitting=false;
              }
              this.saveInfo()
            }
            else{
              this.$message.error('信息不完整')
              return;
            }
          })
        } 
        else{
          this.saveInfo()
        }
      });
    },
    saveInfo(){
      
        this.submitting = true;
        let postData = Object.assign({}, this.formData, {
          CooperationTagList: this.formData.CooperationTagList.map(item => {
            if (item.TagName === undefined) {
              return { TagName: item };
            } else {
              return { ...item };
            }
          })
        });
        const task = this.isEdit ? edit(postData) : insert(postData);
        task.then(_ => {
          this.submitting = false;
          this.$router.push('/enterprise/cooperation');
        }).catch(_ => {
          this.submitting = false;
        });
    },
    deallogo(fieldName, value) {
      this.formData[fieldName] = value;
    },
    dealDescription(fieldName, value) {
      this.formData[fieldName] = value;
    },
    cancle() {
      if (!this.isView) {
        this.$confirm('系统将不会保存您所更改的内容！', '您确定要离开当前页面吗？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: '温馨提示'
          })
          .then(() => {
            this.$router.go(-1)
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.$router.go(-1)
      }
    },
    deleteChildren(arr) {
      const childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].Children) {
          if (childs[i].Children.length) {
            this.deleteChildren(childs[i].Children);
          } else {
            delete childs[i].Children;
          }
        }
      }
      return arr;
    },
    uploadedResourse(fileModel) {
      this.formData.PromotionalVideo = fileModel.Id;
    },
    updateForm(fieldName, value) {
      console.log("修改参数:", fieldName + "值：", value);
      this.companyForm[fieldName] = value;
    },
    areaChange(value) {
      this.companyForm.ProvinceCode = value[0];
      this.companyForm.CityCode = value[1];
      this.companyForm.RegionCode = value[2];
    },
    setEnterpriseInfo(val) {
      console.log(val.value)
      getEnterpriseBaseByName(val.value).then(res => { 
        if(res.Result){
           this.companyForm=res.Result
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.form {
  background: #fff;
  padding: 48px;
  margin-bottom: 20px;
  border-radius: 8px;
  .to {
    ::v-deep label {
      &::before {
        display: none;
      }
    }
  }
}
.info-span {
  display: flex;
  margin-bottom: 16px;
  &:before {
    width: 3px;
    content: '';
    background-color: #00cb8a;
    margin-right: 8px;
  }
}
</style>
